<template>
  <DefaultLayout class-list="bg-[#0F1011]">
    <Head title="Log In" />
    <div class="container-main">
      <LoginForm
        :email="email"
        :message="message"
        :errors="errors"
      />
    </div>
  </DefaultLayout>
</template>

<script>
import { Head } from '@inertiajs/vue3';
import DefaultLayout from '@/Layouts/DefaultLayout.vue';
import LoginForm from '@/Components/Auth/LoginForm.vue';
import { trackEvent } from '@/Utils/analytics.js';

export default {
  components: {
    LoginForm,
    DefaultLayout,
    Head,
  },
  props: {
    errors: {
      type: Object,
      default: () => {},
    },
    email: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
  },
  mounted() {
    trackEvent('login_page_shown', { default: this.defaultValue, });
  },
};
</script>

<style lang="scss" scoped>
.container-main {
    @apply font-sans overflow-x-hidden z-10 h-full flex;
    min-height: calc(100dvh - 40px);
}
</style>
