<template>
  <div class="auth-wrapper">
    <div class="auth-container">
      <MainHeader />
      <BurgerMenu />
      <form
        novalidate
        @submit.prevent="logIn"
        class="auth-container__form"
      >
        <div class="auth-container__title">Log in</div>
        <div
          v-if="message?.length"
          class="auth-container__note mt-[-32px]"
        >
          {{ message }}
        </div>
        <div class="w-full relative">
          <input
            placeholder="Email address"
            type="email"
            id="email"
            v-model="form.email"
            required
            class="auth-container__email"
            :class="{'auth-container__invalid': errors?.email}"
          />
        </div>

        <div
          v-if="errors.email"
          class="auth-container__error"
        >
          <ErrorIcon />
          {{ errors.email }}
        </div>

        <div
          class="w-full relative mt-[12px] lg:mt-[20px]"
          data-aos="fade"
          data-aos-easing="ease-in-out"
        >
          <input
            placeholder="Password"
            autofocus
            @keydown.enter.prevent="logIn"
            :type="passwordFieldType"
            id="password"
            v-model="form.password"
            @focus="isPasswordFocused = true"
            @blur="isPasswordFocused = false"
            class="auth-container__password"
            :class="{'auth-container__invalid': errors?.password}"
            required
          />
          <div
            class="auth-container__password-icon"
            @click="toggleShowPassword"
          >
            <EyeClosed
              v-if="showPassword"
              class="mx-auto"
            />
            <EyeOpened
              v-else
              class="ml-[2px]"
            />
          </div>
        </div>

        <div
          v-if="errors.password"
          class="auth-container__error"
        >
          <ErrorIcon />
          {{ errors.password }}
        </div>

        <Link
          :href="route('password.request')"
          class="auth-container__forgot-password"
          data-aos="fade"
          data-aos-easing="ease-in-out"
        >
          Forgot password?
        </Link>
        <button class="auth-container__button mt-[20px] mb-[15px]">
          <span
            v-if="isLoading"
            class="loading-circle"
          ></span>
          <span v-else>Continue</span>
        </button>
        <div class="auth-container__footer">
          Don't have an account?
          <Link
            :href="route('register')"
            class="auth-container__footer-link"
            @click="signInButtonPressed"
          >
            Sign Up
          </Link>
        </div>
        <div class="auth-separator">
          <div class="separator-divider"></div>
          or
          <div class="separator-divider"></div>
        </div>
        <a
          class="auth-social"
          :href="route('social-auth.redirect', {provider: 'google'})"
        >
          <img
            alt="google"
            width="22"
            height="22"
            src="../../../../resources/images/google.webp"
          />
          <span class="auth-social__text">
            Continue with Google
          </span>
        </a>
        <a
          class="auth-social"
          :href="route('social-auth.redirect', {provider: 'apple'})"
        >
          <img
            alt="google"
            width="22"
            height="22"
            src="../../../../resources/images/apple.webp"
          />
          <span class="auth-social__text"> Continue with Apple </span>
        </a>
      </form>
      <AuthFooter />
    </div>
  </div>
</template>

<script>
import MainHeader from '@/Components/MainHeader.vue';
import BurgerMenu from '@/Components/BurgerMenu.vue';
import EyeOpened from '@/Icons/auth-form/onboarding/EyeOpened.vue';
import EyeClosed from '@/Icons/auth-form/onboarding/EyeClosed.vue';
import { Link } from '@inertiajs/vue3';
import { useForm } from 'laravel-precognition-vue-inertia';
import AuthFooter from '@/Components/Auth/AuthFooter.vue';
import { trackEvent } from '@/Utils/analytics.js';
import ErrorIcon from '@/Icons/auth-form/onboarding/ErrorIcon.vue';

export default {
  components: {
    AuthFooter,
    EyeClosed,
    EyeOpened,
    MainHeader,
    BurgerMenu,
    Link,
    ErrorIcon,
  },
  props: {
    errors: { type: Object, default: () => {} },
    email: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showPassword: false,
      form: useForm('post', route('login.attempt'), {
        email: '',
        password: '',
        remember: false,
      }).setValidationTimeout(500),
      isLoading: false,
    };
  },
  computed: {
    passwordFieldType() {
      return this.showPassword ? 'text' : 'password';
    },
    passwordIcon() {
      return this.showPassword ? 'EyeOpened' : 'EyeClosed';
    },
  },
  methods: {
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },
    logIn() {
      this.isLoading = true;
      this.form.submit({
        onSuccess: () => {
          trackEvent('login_page_pressed', { default: this.defaultValue, });
          this.form.reset();
        },
        onFinish: () => {
          this.isLoading = false;
        },
      });
    },
    signInButtonPressed() {
      trackEvent('signin_pressed');
    },
  },
};
</script>

<style lang="scss" scoped>
.auth-wrapper {
    @apply w-full bg-[#0F1011];
}

.auth-container {
    @apply bg-[#0F1011] w-[375px] mt-0 m-auto pt-[80px] flex flex-col items-center h-full;
    @screen lg {
        @apply w-[1280px] pt-[2px] mx-auto;
    }
}

.auth-container__form {
    @apply w-[350px] px-[15px] pb-[60px] bg-[#232425] rounded-[15px] flex flex-col items-center mt-[20px];
    @screen lg {
        @apply w-[380px] px-[30px] mt-[100px];
    }
}

.auth-container__title {
    @apply font-sans text-[32px] font-normal leading-[70px] tracking-[0px] text-center text-white my-[40px];
}

.auth-container__email {
    @apply font-sans relative px-[20px] pl-[16px] pr-[46px] rounded-[15px] w-full h-[52px] bg-[#232425] text-[16px] font-normal leading-[19px] tracking-[0px] text-left text-white;
    border: 1px solid #929292;

    &::placeholder {
        @apply text-[16px] font-normal leading-[19px] tracking-[0px] text-left text-[#929292];
    }

    &:focus,
    &:active {
        border-color: #0fab6d;
        box-shadow: none;
    }

    &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 50px #232425 inset;
        -webkit-text-fill-color: #ffffff;
    }

    &:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0 50px #232425 inset;
        -webkit-text-fill-color: #ffffff;
    }
}

.auth-container__password {
    @apply font-sans relative px-[20px] pl-[16px] pr-[46px] rounded-[15px] w-full h-[52px] bg-[#232425] text-[16px] font-normal leading-[19px] tracking-[0px] text-left text-white;
    border: 1px solid #929292;

    &::placeholder {
        @apply text-[16px] font-normal leading-[19px] tracking-[0px] text-left text-[#929292];
    }

    &:focus,
    &:active {
        border-color: #0fab6d;
        box-shadow: none;
    }

    &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 50px #232425 inset;
        -webkit-text-fill-color: #ffffff;
    }

    &:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0 50px #232425 inset;
        -webkit-text-fill-color: #ffffff;
    }
}

.auth-container__password-icon {
    @apply w-[24px] absolute cursor-pointer flex flex-row items-center;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
}

.auth-container__forgot-password {
    @apply font-sans text-[14px] font-normal leading-[32px] tracking-[0px] cursor-pointer text-[#0FAB6D] mt-[10px];
    &:hover {
        @apply underline;
    }
}

.auth-container__button {
    @apply font-sans w-[320px] h-[52px] text-[16px] font-normal leading-[19px] tracking-[0px] text-center text-white rounded-[16px];

    background: linear-gradient(90deg, #0fab6d 0%, #6c3da9 100%);
    box-shadow: 0 0 4px 0 #00000018;
    transition: background 1s ease-out;

    &:hover {
        background: linear-gradient(270deg, #0fab6d 0%, #6c3da9 100%);
    }

    &:disabled {
        @apply opacity-30;
    }
}

.auth-container__error {
    @apply flex items-center gap-[4px] font-sans text-[14px] font-normal leading-[19px] tracking-[0px] text-[#FF0000] mt-[6px] text-left w-full;
}

.auth-container__invalid {
    border: 1px solid red !important;
}

.auth-container__footer {
    @apply font-sans text-[14px] font-normal leading-[32px] tracking-[0px] text-white;
}

.auth-separator {
    @apply font-sans text-[14px] font-normal text-white flex w-full justify-between items-center py-3;
}

.separator-divider {
    @apply w-[140px] bg-[#383A3C] h-[1px];
}

.auth-social {
    @apply w-full border-[1px] border-[#878787] rounded-[15px] mb-3 flex justify-center py-3 gap-2;
}

.auth-social__text {
    @apply text-white font-sans text-[16px] font-semibold;
}

.auth-container__footer-link {
    @apply cursor-pointer text-[#0FAB6D];
    &:hover {
        @apply underline;
    }
}

.auth-container__note {
    @apply font-sans text-[14px] font-normal leading-[32px] tracking-[0px] text-white;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
